import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useEffect, useState } from "react";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/posts" element={<Posts/>}/>
        <Route path="*" element={<NoPage/>}/>
      </Routes>
    </BrowserRouter>
  );
}

function Home(props) {
  return (
    <h1>Home</h1>
  );
};

function NoPage(props) {
  return (
    <div>
      <h1>404: Page not found</h1>
    </div>
  )
}

function Posts() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch('/api/posts');
      console.log(resp);
      const postsResp = await resp.json();
      console.log(postsResp);
      setPosts(postsResp);
    };

    getPosts();

  }, []);

  return (
    <div>
      <h1>Posts</h1>
      {posts.map(post => (
        <div key={post.id}>
          <h2>
            <Link to={`/posts/${post.id}`}>{post.title}</Link>
          </h2>
        </div>
      ))}
    </div>
  )
}

export default App;
